const TopSearchLinks = [
  {
    label: 'Blackboard Tutorials',
    url: 'https://my.phoenix.edu/public/search.html?query=Blackboard+Tutorials',
    target: '_blank',
  },
  {
    label: 'Microsoft Office Tutorials',
    url: 'https://my.phoenix.edu/public/search.html?query=Microsoft+Office+Tutorials',
    target: '_self',
  },
  {
    label: 'Policies and Procedures',
    url: 'https://my.phoenix.edu/public/search.html?query=Policies+and+Procedures',
    target: '_blank',
  },
];

export default TopSearchLinks;
