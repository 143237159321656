import { Card, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StandardLink from '@/components/atoms/standard-link';

export const StyledCard = styled(Card)(({ theme }) => ({
  margin: theme.spacing(0.1),
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  textAlign: 'center',
  height: '100%',
  boxShadow: `${theme.spacing(0.0625)} ${theme.spacing(0.0625)}  ${theme.spacing(0.5)}  ${theme.spacing(-0.1875)} ${theme.palette.almostBlack.l50}, ${theme.spacing(0.0625)} ${theme.spacing(0.0625)} ${theme.spacing(0.125)} ${theme.palette.almostBlack.l80}`,
}));

export const HeadingSection = styled(Typography)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  textAlign: 'left',
  background: theme.palette.almostBlack.l90,
  padding: `${theme.spacing(2.4)} ${theme.spacing(3.2)}`,
  fontSize: theme.spacing(2.4),
  fontWeight: 400,
  lineHeight: theme.spacing(2.8),
}));

export const LinkSection = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(4),
}));

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  display: 'inline',
  borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.common.black}`,
  ':focus': {
    outline: `${theme.spacing(0.2)} solid ${theme.palette.common.black}`,
    outlineOffset: theme.spacing(0.5),
    borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.primary.main}`,
  },
  ':hover': {
    borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.primary.main}`,
  },
}));

export const LinkText = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  fontWeight: 400,
  lineHeight: theme.spacing(2.4),
}));

export const LinkGridItem = styled(Grid)(({ theme }) => ({
  width: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontWeight: 400,
  fontSize: theme.spacing(2),
}));
