import { FormControl, Grid, InputLabel, Input, IconButton, List, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StandardLink from '@/components/atoms/standard-link';

export const SearchBoxGrid = styled(Grid)(() => ({
  '&.MuiGrid-item': {
    paddingTop: 0,
  },
}));

export const SearchBoxContainer = styled(FormControl)(({ theme }) => ({
  display: 'flex',
  margin: theme.spacing(0),
}));

export const SearchBoxLabel = styled(InputLabel)(({ theme }) => ({
  paddingTop: theme.spacing(1.8),
  fontSize: theme.spacing(1.2),
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(2),
    fontSize: theme.spacing(1.6),
  },
  color: theme.palette.almostBlack.l30,
}));

export const SearchBoxInput = styled(Input)(({ theme }) => ({
  margin: theme.spacing(0),
  backgroundColor: theme.palette.almostBlack.l90,
  border: 0,
  fontSize: theme.spacing(1.6),
  '& input': {
    height: theme.spacing(4),
    padding: `${theme.spacing(2)} ${theme.spacing(1.2)} ${theme.spacing(0.6)} ${theme.spacing(1.2)}`,
    marginRight: theme.spacing(1),
  },
  '& input:focus': {
    outline: `${theme.spacing(0.2)} solid ${theme.palette.common.black}`,
    outlineOffset: theme.spacing(-0.2),
  },
}));

export const SearchBoxIconButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1.2),
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.almostBlack.l90,
  '& .Mui-focusVisible': {
    borderTopRightRadius: theme.spacing(3.0),
    borderBottomRightRadius: theme.spacing(3.0),
    borderTopLeftRadius: theme.spacing(0),
    borderBottomLeftRadius: theme.spacing(0),
    background: 'none',
  },
  '&:hover': {
    background: theme.palette.common.white,
  },
  '&:focus': {
    outline: `${theme.spacing(0.2)} solid ${theme.palette.common.black}`,
    outlineOffset: theme.spacing(0.4),
  },
}));

export const TopSearchesGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
  },
}));

export const TopSearchesList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'space-between',
  '&.MuiList-padding': {
    paddingTop: theme.spacing(1.3),
  },
  '& .MuiListItem-root': {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(1.1),
    '&.MuiListItem-gutters': {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },
    '& .MuiLink-underlineHover:hover': {
      color: theme.palette.text.primary,
    },
  },
  [theme.breakpoints.up('md')]: {
    '&.MuiListItem-padding': {
      paddingTop: theme.spacing(0),
    },
    flexDirection: 'row',
  },
}));

export const TopSearchesHeading = styled(Typography)(({ theme }) => ({
  fontSize: theme.spacing(1.4),
  fontWeight: 'bold',
  color: theme.palette.text.disabled,
  display: 'inline-block',
  textTransform: 'uppercase',
  marginRight: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    fontSize: theme.spacing(1.4),
  },
}));

export const TopSearchesLink = styled(StandardLink)(({ theme }) => ({
  fontSize: theme.spacing(1.6),
  color: theme.palette.text.primary,
  borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.text.primary}`,
  borderRadius: theme.spacing(0),
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  lineHeight: theme.spacing(1.7),
  marginLeft: theme.spacing(0),
  '&:hover, &:focus': {
    textDecoration: 'none',
    borderBottom: `${theme.spacing(0.3)} solid ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0.3),
  },
}));
