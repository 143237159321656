import { useClickEventTracking } from '@/hooks/useClickEventTracking';
import { StyledCard, HeadingSection, LinkSection, StyledLink, LinkText, LinkGridItem } from './ResourceCard.styles';

export type ResourceCardProps = {
  parentComponentName?: string,
  title: string;
  links: { href: string, text: string, target?: string }[]
};

function ResourceCard({ parentComponentName = '', title = '', links = [] }: ResourceCardProps) {
  const componentName = `${parentComponentName}_ResourceCard`;
  const trackClick = useClickEventTracking();
  return (
    <StyledCard>
      <HeadingSection variant="inherit">
        {title}
      </HeadingSection>
      <LinkSection container spacing={0.5}>
        {links.map((link, i) => (
          <LinkGridItem key={link.text} item>
            <StyledLink
              data-testid={`card-link-${i}`}
              href={link.href}
              onClick={() => {
                trackClick(
                  componentName,
                  [{ cardTitle: title },
                    { text: link.text },
                    { url: link.href },
                    { target: link.target }],
                );
              }}
            >
              <LinkText variant="body5" noWrap>{link.text}</LinkText>
            </StyledLink>
          </LinkGridItem>
        ))}
      </LinkSection>
    </StyledCard>
  );
}

export default ResourceCard;
